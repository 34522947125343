import React from "react"
import List from "../components/faq/List"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import ContactMe from "../components/translation/ContactMe"
import Description from "../components/translation/Description"
import Service from "../components/translation/Service"

const translation = () => {
  return (
    <Layout>
      <Seo title="翻訳サービス" />
      <Description />
      <Service />
      <ContactMe />
    </Layout>
  )
}

export default translation
