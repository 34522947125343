import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { PATH } from "../../constants"
import HeadlineM from "../HeadlineM"

const Description = () => {
  return (
    <div className="mt-16">
      <HeadlineM title="翻訳サービス" />
      <div className="mt-8">
        マンガ、短編小説、ビジネスレターやEメール、大企業や政府のアンケートなど、さまざまな分野の日本語から英語への翻訳を経験してきた英語ネイティブのマークが翻訳のお手伝いをします。
      </div>
      <div className="mt-4">
        キャッチフレーズなどの短い翻訳から文章翻訳まで、一度相談してみたいという方は
        <Link to={PATH.CONTACT} className="font-bold font-ZenMaruGathic">
          &nbsp;お問い合わせ&nbsp;
        </Link>
        からお気軽にご連絡ください！内容に応じて打ち合わせをした上でお見積りを出します。
      </div>
      <div className="m-auto md:mx-[calc(50%_-_50vw)]">
        <div className="flex flex-col-reverse grid-cols-2 py-8 text-center md:pt-20 md:grid">
          <div className="py-6 mx-auto space-y-5 md:p-4 md:max-w-md ">
            <p className="text-xl md:text-2xl font-ZenMaruGathic text-DeepGreen">
              プロによる翻訳は必要なのか？
            </p>
            <div className="text-left">
              近年の技術の発達により、機械翻訳でも手軽にそれなりに意味の通じる英語翻訳ができるようになってきました。しかしまだまだそれは完全ではなく、日本語特有の表現に起因する不正確さや、誤解を招きやすい不自然な英語表現が含まれることが多々あります。
              <br />
              機械翻訳は文化的な背景を持つ言葉や、感情を乗せた言葉の表現が苦手とされています。そのため「ネイティブ英語」にはまだまだ遠く、ネイティブの人からしたらとても違和感がある文章になってしまいます。
              <br />
              <br />
              違和感なく細かいニュアンスを正確に伝えるためには、AIなどの機械翻訳ではなく、プロ翻訳家の仕事がまだまだ必要なのです。
            </div>
          </div>
          <StaticImage
            src="../../images/thumbnail-book.png"
            alt="girl"
            className="mt-8 md:mt-0"
          />
        </div>
        <div className="grid-cols-2 text-center md:grid md:pb-8">
          <StaticImage
            src="../../images/thumbnail-coffee.png"
            alt="rainbow"
            className=""
          />
          <div className="py-6 m-auto space-y-5 md:p-4 md:max-w-md ">
            <p className="text-xl md:text-2xl font-ZenMaruGathic text-DeepGreen">
              和製英語にも気をつけて！
            </p>
            <div className="text-left">
              日本では古くから外来語を日本語に取り入れてきて多くのカタカナの言葉になっていますが、それらが全て英語とは限りません。英語だと思って使っても外国人には意味が通じなかったりします。
              例えば、
              <br />
              パンに具材を挟んでトーストする「ホットサンド」は、英語にそのまま訳すと「Hot
              Sand（熱い砂）」という意味になってしまいます。
              <br />
              「え、これも通じないのか」という和製英語は意外と身近にたくさんあるので注意が必要です。
              <br />
              <br />
              Wombat
              Englishが提供するサービスでは、原文の日本語の意味を正確かつ自然に英語に翻訳し、原作者の意図を正しく解釈して伝えていきます。
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Description
