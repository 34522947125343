import React from "react"
import { PATH } from "../../constants"
import Button from "../Button"

const ContactMe = () => {
  return (
    <div className="mt-16">
      <p className="text-center">
        ご相談・お申し込みなど、お気軽にご連絡ください！
      </p>
      <div className="flex justify-center mt-5">
        <Button title="お問い合わせ" to={PATH.CONTACT} />
      </div>
    </div>
  )
}

export default ContactMe
