import React from "react"
import HeadlineM from "../HeadlineM"

const Service = () => {
  const items = [
    {
      title: "漫画",
      desc: "吹き出し箇所や効果音び翻訳、コマの形に合わせた編集など。",
    },
    {
      title: "各種ドキュメント",
      desc: "企業や政府の大事な文書、従業員向けのマニュアル翻訳など。",
    },
    {
      title: "ビジネスレター・メール",
      desc: "お客様に向けたビジネス用メールテンプレートの作成など",
    },
    {
      title: "店のメニュー・看板",
      desc: "お店のメニュー、店内の案内表示、看板など。",
    },
    {
      title: "パンフレット・ポスター",
      desc: "外国人向けのパンフレット、ポスター、ガイドブックの翻訳など。",
    },
    {
      title: "ネイディブ英語チェック",
      desc: "すでにある英語文書をネイティブが最終チェックして添削します",
    },
  ]
  return (
    <div className="mt-16">
      <HeadlineM title="サービス事例" />
      <div className="grid-cols-3 gap-4 mt-8 space-y-4 text-center md:grid md:space-y-0">
        {items.map(item => {
          return (
            <div className="p-5 bg-LightBlue" key={item.title}>
              <div className="mb-3 text-xl">{item.title}</div>
              <div>{item.desc}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Service
